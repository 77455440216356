//$font-dir: '../fonts/';
//$font-name: 'PT Sans, sans-serif';
//$yellow: #fcdb00;

@mixin transform($transforms){
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}
@mixin transition($transition){
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin box-shadow($shadow){
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

